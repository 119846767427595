
import { Delegates, Events as eventApi } from "@/api";
import { OfficeType } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import DelegatesList from "@/components/delegates/DelegatesList.vue";
import Spinner from "@/components/shared/Spinner.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

import CompanyPrice from "@/components/events/CompanyPrice.vue";
import ScheduleQualification from "@/components/events/ScheduleQualification.vue";
import { namespace } from "vuex-class";

const Events = namespace("events");
const Auth = namespace("auth");
const Company = namespace("company");
const Instructor = namespace("instructors");

@Component({
  components: {
    DelegatesList,
    Card,
    PageTitle,
    ValidationObserver,
    ValidationProvider,
    CompanyPrice,
    Spinner,
    ScheduleQualification
  }
})
export default class EditEventPage extends Vue {
  OCCUPATIONAL_QUALIFICATION_ID = 8;

  currentDate = moment().format("YYYY-MM-DD");
  nextDay = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  selectedDelegates: Array<{ id: number; companyId: number }> = [];
  bookingTypes = [];
  courseDurationId = 1;
  courseOverview: Array<{ title: string; content: any }> = [];
  isSingleSelect = false;
  tableHeader = {
    title: "Assign delegates",
    icon: "mdi-account"
  };
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu = false;
  companyPriceValid = false;
  durationVal: any = 0;
  selectedCourseCatId: any = null;

  eventStartDate = moment().format("YYYY-MM-DD");
  headersTitle = [{ icon: "mdi-file-edit", header: "Edit booking Request" }];
  loading = false;
  fetchLoading = false;

  //Action
  @Events.Action("getCoursesList")
  getCoursesList!: () => Promise<void>;
  @Events.Action("getAuditsList")
  getAuditsList!: () => Promise<any>;
  @Events.Action("getOnlineCoursesList")
  private getOnlineCoursesList!: () => Promise<any>;
  @Events.Action("getEventTypes")
  getEventTypes!: () => Promise<any>;
  @Events.Action("getOfficeTypes")
  getOfficeTypes!: () => Promise<void>;
  @Events.Action("getCoursesDurations")
  getCoursesDurations!: () => Promise<void>;
  @Events.Action("getCoursesInfo")
  getCoursesInfo!: (id: string) => Promise<void>;
  @Events.Action("getAuditInfo")
  private getAuditInfo!: (id: string) => Promise<any>;
  @Events.Action("getOnlineCoursesInfo")
  private getOnlineCoursesInfo!: (id: string) => Promise<any>;
  @Events.Action("updEvent")
  updEvent!: (params: {
    eventData: any;
    selectedDelegates: any;
  }) => Promise<void>;
  @Instructor.Action("getInstructorslistByCourseID")
  private getInstructorslistByCourseID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Instructor.Action("getInstructorslistByAuditID")
  private getInstructorslistByAuditID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Instructor.Action("getInstructorslistByOnlineCourseID")
  private getInstructorslistByOnlineCourseID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Company.Action("getListCompanyWithPagination")
  getListCompanyWithPagination!: (data: any) => Promise<void>;
  @Instructor.Action("getInstructorsListWithoutPagination")
  getInstructorsListWithoutPagination!: () => Promise<void>;
  @Events.Action("getEventById")
  getEventById!: (eventId: string) => Promise<any>;
  @Events.Action("removeEvent")
  removeEvent!: (eventId: string | number) => Promise<void>;

  // mutations
  @Events.Mutation("startFetch")
  eventStartFetch!: () => void;
  @Company.Mutation("startFetch")
  companyStartFetch!: () => void;
  @Events.Mutation("setEventType")
  setEventType!: (type: any) => void;
  @Events.Mutation("updCompanies")
  updCompanies!: (data: any) => void;
  @Instructor.Mutation("setInstructors")
  setInstructors!: (data: any) => void;
  @Events.Mutation("setOfflineTrainingRegister")
  setOfflineTrainingRegister!: (item: any) => void;

  //STATE
  @Events.State("courses") courses: any;
  @Events.State("audits") audits: any;
  @Events.State("onlineCourses") onlineCourses: any;
  @Events.State("officeTypes") officeTypes: any;
  @Events.State("event") event: any;
  @Events.State("durations") durations: any;
  @Events.State("coursesInfo") coursesInfo: any;
  @Instructor.State("instructors") instructors: any;

  //GETTERS
  @Auth.Getter("getUser")
  getUser!: object | null;

  async created() {
    this.loading = true;
    try {
      this.eventStartFetch();
      this.companyStartFetch();
      // await this.getCoursesList();
      await this.fetchData();
    } catch (e) {
      await this.$error(e);
    }
    this.loading = false;
  }
  async onCreated() {
    // await this.getEventById(this.eventId);
    const info = await eventApi.info(this.eventId);

    this.setOfflineTrainingRegister(info.offlineTrainings);

    // this.event.offlineTrainings.push({ ...data, delegates: [] });
  }
  onDeleteOfflineTraining(id: number) {
    this.event.offlineTrainings = this.event.offlineTrainings.filter(
      (el: any) => el.id != id
    );
  }

  async fetchData() {
    const loadedEvent = await this.getEventById(this.eventId);
    this.bookingTypes = await this.getEventTypes();

    await this.getOfficeTypes();

    await this.getCoursesDurations();
    await this.getListCompanyWithPagination(!!loadedEvent.company.length);
    if (!loadedEvent.completed) {
      await this.getInstructorsListWithoutPagination();
    }
    const courseType = this.bookingTypes.find(
      (type: any) => type.id === this.event.eventTypeId
    );

    this.setEventType(courseType);
    await this.handleCourseChange();
    this.eventStartDate = moment(
      this.event.eventStartDate,
      "DD-MM-YYYY"
    ).format("YYYY-MM-DD");
    await this.getDelegateByEvent();
    // if (this.event?.eventType?.name === "Course") {
    this.durationVal = this.event.duration;
    this.onDurationSelect();
    // }

    this.updCompanies(loadedEvent.company);
  }

  get buttonsArray() {
    return [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back",
        disabled: false
      },
      {
        text: "Delete",
        icon: "mdi-delete",
        color: "gray",
        action: "delete",
        disabled: this.event.blockDeletion
      },
      {
        text: "Save",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "save",
        disabled: false
      }
    ];
  }
  get isOnlineCourse() {
    return this.event?.eventType?.name === "Online Course";
  }

  get isTransportDisabled(): boolean {
    return !this.addressRules;
  }
  get eventId(): string {
    return this.$route.params.id;
  }
  get offlineTrainings() {
    return this.event?.offlineTrainings || [];
  }

  get addressRules(): string {
    const currentType = this.event?.officeTypeId || 0;
    const index = this.officeTypes.findIndex(
      (officeType: OfficeType) => officeType.id === currentType
    );
    if (index !== -1) {
      const selectedOfficeType: OfficeType = this.officeTypes[index];
      if (selectedOfficeType) {
        return selectedOfficeType.validateAddress ? "required" : "";
      }
    }
    return "";
  }
  get formattedEventStartDate() {
    return moment(this.eventStartDate, "YYYY-MM-DD").format("DD/MM/YYYY");
  }
  // get formattedEventEndDate() {
  //   return moment(this.event.eventFinishDate,"DD-MM-YYYY").format("DD/MM/YYYY");
  // }
  get checkUser() {
    const currentUser: any = this.getUser;
    const isManager =
      currentUser && currentUser.permissions
        ? Boolean(
            currentUser.permissions.find(
              (permission: string) => permission === "create_role"
            )
          )
        : false;
    return isManager;
  }
  get isOccupationalQualification() {
    return this.coursesInfo?.category?.id == this.OCCUPATIONAL_QUALIFICATION_ID;
  }

  @Watch("event.eventableId")
  async onSelectCourse(id: number, oldId: number) {
    if (id) {
      if (oldId > 0) this.event.instructorId = null;
      if (this.event?.eventType?.name === "Course") {
        await this.getCoursesList();
        await this.fetchCourseInfo(id);
      }

      if (this.event?.eventType?.name === "Audit") {
        await this.fetchAuditInfo(id);
        await this.getAuditsList().catch(e => {
          this.$error({ error: e });
        });
      }

      if (this.event?.eventType?.name === "Online Course") {
        await this.fetchOnlineCourseInfo(id);
        await this.getOnlineCoursesList().catch(e => {
          this.$error({ error: e });
        });
      }
    }
  }

  @Watch("coursesInfo")
  updCompaniesPrice() {
    if (this.coursesInfo) {
      const newCompanies = this.event.company.map((company: any) => {
        return { ...company, price: this.coursesInfo.price };
      });
      this.updCompanies(newCompanies);
    }
  }

  @Watch("event.company", { deep: true })
  editSumCard() {
    if (this.event.company?.length) {
      this.updPrice();
    }
    // const a = this.courseOverview;
    // if (a && a[3]?.content) {
    //   a[3].content = `${new Intl.NumberFormat("en", {
    //     style: "currency",
    //     currency: "ZAR",
    //     minimumFractionDigits: 2
    //   })
    //     .format(this.event.price)
    //     .substr(2)}`;
    // }
  }

  @Watch("selectedDelegates")
  updAssign() {
    this.updPrice();
    // const a = this.courseOverview;
    // if (a && a[3]?.content) {
    //   a[3].content = `${new Intl.NumberFormat("en", {
    //     style: "currency",
    //     currency: "ZAR",
    //     minimumFractionDigits: 2
    //   })
    //     .format(this.event.price)
    //     .substr(2)}`;
    // }
  }

  async getDelegateByEvent() {
    this.selectedDelegates = [];
    const delegates = await Delegates.delegatesForEvent(this.eventId);
    if (delegates.length) {
      delegates.forEach(
        (delegate: {
          id: number;
          name: string;
          surname: string;
          companyId: number;
        }) => {
          this.selectedDelegates.push({
            id: delegate.id,
            companyId: delegate.companyId
          });
        }
      );
    }
  }

  updPrice() {
    // let sum = 0;
    // this.selectedDelegates.forEach((d: any) => {
    //   this.event.company?.forEach((c: any) => {
    //     if (d.companyId === c.companyId) {
    //       sum += +c.price;
    //     }
    //   });
    // });
    // console.log("summ: ", sum / (this.selectedDelegates.length || 1));

    // this.event.price = sum / (this.selectedDelegates.length || 1);

    if (this.event.company) {
      const price = this.event.company.reduce((acc: number, company: any) => {
        return (acc += Number(company.price) || 0);
      }, 0);
      this.event.price = price.toFixed(2);
    }
  }

  formatDate(date: string | undefined): string {
    if (!date) return "";
    const [day, month, year] = date.split("-");
    return `${month}/${day}/${year}`;
  }

  async onGoBack() {
    await this.$router.push({ name: "CourseEvents" });
  }

  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  onDurationSelect() {
    const localDurations: any = this.durations;
    this.courseOverview[1].content = localDurations.find(
      (duration: any) => duration.id === this.durationVal
    )?.name;
  }

  async save() {
    try {
      this.updPrice();
      this.buttonsArray.forEach(btn => {
        btn.disabled = true;
      });
      this.event.transportPrice += "";
      const eventData = JSON.parse(JSON.stringify(this.event));

      eventData.eventStartDate = moment(
        this.eventStartDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      eventData.eventableType = this.event.eventType?.eventableType;
      eventData.courseDurationId = this.courseDurationId;

      eventData.courseDurationId = this.durationVal.id || this.durationVal;
      delete eventData.duration;

      delete eventData.name;
      // eventData.courseDurationId = this.coursesInfo.durationDays || 1;
      // if (this.event.eventTypeId == 2) eventData.courseId = eventData.auditId;
      // if (!this.event.price) eventData.price = this.coursesInfo.price;

      await this.updEvent({
        eventData,
        selectedDelegates: this.selectedDelegates
      });
      await this.$success("<strong>Success!</strong> Event has been updated!");

      if (this.$route.name === "EditEvent") {
        await this.$router.push({ name: "CourseEvents" });
      }
    } catch (e) {
      (this.$refs.eventForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e);
      await this.$error(e);
    } finally {
      this.buttonsArray.forEach(btn => {
        btn.disabled = false;
      });
    }
  }

  getCompaniesGoal(data: any) {
    if (data) {
      this.event.company = data;
    }
  }

  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await this.removeEvent(this.eventId);
        await this.$success("Event has been deleted!");

        await this.onGoBack();
      } catch (e) {
        await this.$error(e);
      }
    }
  }

  async handleCourseChange() {
    const eventData: any = this.event;
    if (this.event?.eventType?.name === "Course") {
      this.getCoursesList();
      await this.fetchCourseInfo(eventData.course.id.toString());
      this.selectedCourseCatId = this.coursesInfo.category.id;
    }
    if (this.event?.eventType?.name === "Audit") {
      this.getAuditsList();
      await this.fetchAuditInfo(eventData.audit.id.toString());
    }
    if (this.event?.eventType?.name === "Online Course") {
      this.getOnlineCoursesList();
      await this.fetchOnlineCourseInfo(eventData.onlineCourse.id.toString());
    }
  }

  async fetchAuditInfo(id: number) {
    try {
      if (this.event.completed) {
        this.setInstructors([this.event.instructor]);
      } else {
        await this.getInstructorslistByAuditID({ id, active: 1 });
      }
      await this.getAuditInfo(id.toString());
      this.courseDurationId = this.coursesInfo.duration;
    } catch (e) {
      await this.$error([e]);
    }
  }

  async fetchCourseInfo(id: number) {
    try {
      if (this.event.completed) {
        this.setInstructors([this.event.instructor]);
      } else {
        await this.getInstructorslistByCourseID({ id, active: 1 });
      }
      await this.getCoursesInfo(id.toString());
      const course = this.coursesInfo;
      this.selectedCourseCatId = course.category.id;

      const durationName =
        this.coursesInfo.category.id === 3 ? "" : course.duration;
      const price = `${new Intl.NumberFormat("en", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2
      }).format(course.price)}`;

      this.courseOverview = [
        { title: "Name", content: course?.name },
        {
          title: "Duration",
          content: durationName?.name
        },
        { title: "Certificate", content: course.courseType },
        {
          title: "Cost Per Day",
          content: price
        },
        {
          title: course.dol ? "DOL #" : "",
          content: course.dol ? course.dol : ""
        }
      ];
      // if (this.coursesInfo.category.id === 3) {
      this.durationVal = this.event.duration;
      this.onDurationSelect();
      // }
    } catch (e) {
      await this.$error(e);
    }
  }

  async fetchOnlineCourseInfo(id: number) {
    try {
      if (this.event.completed) {
        this.setInstructors([this.event.instructor]);
      } else {
        await this.getInstructorslistByOnlineCourseID({ id, active: 1 });
      }
      await this.getOnlineCoursesInfo(id.toString());

      const course = this.coursesInfo;
      this.courseDurationId = course.duration.id;
      const durationName =
        this.coursesInfo.category.id === 3 ? "" : course.duration;
      this.courseOverview = [
        { title: "Name", content: course?.name },
        {
          title: "Duration",
          content: durationName?.name
        },
        { title: "Certificate", content: course?.type?.name },
        {
          title: "Cost Per Day",
          content: `${new Intl.NumberFormat("en", {
            style: "currency",
            currency: "ZAR",
            minimumFractionDigits: 2
          })
            .format(this.coursesInfo.price || 0)
            .substr(2)}`
        },
        {
          title: course.dol ? "DOL #" : "",
          content: course.dol ? course.dol : ""
        }
      ];
      if (this.coursesInfo.category.id === 3) {
        this.durationVal = course.duration;
      }
    } catch (e) {
      await this.$error(e);
    }
  }

  removeAssign(company: number) {
    const newDelegates: { id: number; companyId: number }[] = [];
    this.selectedDelegates.forEach(
      (d: { id: number; companyId: number }, index: number) => {
        if (d.companyId !== company) {
          this.selectedDelegates.splice(index, 1);
          newDelegates.push(d);
        }
      }
    );
    this.selectedDelegates = newDelegates;
  }
}
